<template>
  <!--机构端 校园筛查 筛查计划管理 筛查计划查询  -->
  <div class="page">
    <div class="tabs-section">
      <el-tabs
        v-if="isSchoolId === 1"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="我创建的筛查计划" name="0"></el-tab-pane>
        <el-tab-pane label="学校上报的筛查计划" name="1"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="header">
      <div class="header-input" v-if="activeName == 1">
        <el-select
          v-model="selectSchool"
          placeholder="请选择学校"
          @change="getListData"
        >
          <el-option
            v-for="item in schoolList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="header-search" v-if="activeName == 0">
        <el-input
          maxlength="20"
          placeholder="请输入筛查计划名称"
          class="search"
          prefix-icon="el-icon-search"
          v-model="searchValue"
        ></el-input>
        <div class="header-button-action">
          <el-button type="primary" size="mini" round @click="search"
            >搜索</el-button
          >
          <el-button type="primary" size="mini" round @click="reset"
            >重置</el-button
          >
        </div>
      </div>
      <div class="header-button">
        <div class="header-button-add">
          <el-button
            type="primary"
            size="mini"
            round
            icon="el-icon-plus"
            @click="goTo('/detection/screeningPlanAdd')"
            >新增计划</el-button
          >
        </div>
      </div>
    </div>
    <div class="content">
      <div class="table-main">
        <el-table
          :data="tablePageData"
          @selection-change="handleSelectionChange"
          :row-class-name="tableRowClassName"
          style="width: 100%"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column
            prop="screeningName"
            :formatter="formNull"
            label="筛查计划名称"
          >
          </el-table-column>
          <!-- <el-table-column prop="schoolName" :formatter="formatterSchool" label="学校信息" align="center">
          </el-table-column> -->
          <el-table-column
            prop="remark"
            :formatter="formNull"
            label="备注"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="text"
                @click="goTo('/detection/screeningPlanEdit', scope.row)"
                >修改</el-button
              >
              <el-button
                size="small"
                type="text"
                class="delete-color"
                slot="reference"
                @click="delScreening(0, scope.$index, scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
          <div slot="empty">
            <div :style="{ marginTop: '52px' }">
              <img src="@/assets/detection/image/404.png" alt="" width="333" />
            </div>
          </div>
        </el-table>
        <div class="table-page">
          <el-button
            :type="batchNum ? 'primary' : 'disabled'"
            :disabled="batchNum ? false : true"
            size="mini"
            class="delete-button"
            round
            @click="delScreening(1)"
            >批量删除({{ batchNum }})</el-button
          >
          <pagination
            :current-page="startPage"
            :total="total"
            :page-size="pageSize"
            @currentChange="handleCurrentChange"
            @sizeChange="setPageSize"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screeningService from '@/globals/service/detection/screening.js'
import selectService from '@/globals/service/detection/select.js'
import storage from '@/globals/storage/index.js'

export default {
  data () {
    return {
      isSchoolId: null,
      // 自建和上传
      activeName: 0,
      // 选择学校Id
      selectSchool: null,
      schoolList: [],
      searchValue: '',
      total: 0,
      startPage: 1, // 当前页码
      pageSize: 8,
      tableData: [],
      tablePageData: [],
      multipleSelection: [],
      batchNum: 0,
      searchStatue: false
    }
  },
  created () {
    const schoolId = storage.get('schoolId')
    this.isSchoolId = schoolId ? 0 : 1
  },
  mounted () {
    this.getListData()
    this.getSchoolList()
  },
  methods: {
    handleClick () {
      this.selectSchool = null
      this.getListData()
    },
    getSchoolList () {
      let data
      selectService.school({ startPage: 1, pageSize: 1000 }).then((res) => {
        // console.log('res22', res)
        // const schoolId = storage.get('schoolId')
        // if (schoolId) {
        //   data = res.data.schoolList.filter(item => item.id === schoolId)
        // } else {
        //   data = res.data.schoolList
        // }
        data = res.data.schoolList
        data = data.map((item) => {
          return {
            value: item.id,
            label: item.schoolName
          }
        })
        this.schoolList = data
        if (this.schoolList[0]) {
          this.selectSchool = this.schoolList[0].value
          // this.getListData()
        }
      })
    },
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    getListData () {
      const data = {
        type: this.activeName,
        startPage: this.startPage,
        pageSize: this.pageSize
      }
      if (this.selectSchool) data.schoolId = this.selectSchool
      screeningService.list(data).then((res) => {
        if (res.data.totalPage) {
          this.total = res.data.totalPage
          this.tablePageData = res.data.list
        } else {
          this.total = 0
          this.tablePageData = []
        }
      })
    },
    search () {
      const searchValue = this.searchValue + ''
      if (searchValue === '') {
        this.$message.error('请输入搜索内容')
        return false
      }
      this.startPage = 1
      screeningService
        .search({ type: this.activeName, name: searchValue })
        .then((res) => {
          this.searchStatue = true
          this.total = res.data.length || 0
          if (this.total) {
            this.tableData = res.data
          } else {
            this.tableData = []
          }
          this.dataPages()
        })
    },
    dataPages () {
      const tableData = this.tableData
      const startPage = (this.startPage - 1) * this.pageSize
      if (this.total) {
        this.tablePageData = tableData.slice(
          startPage,
          startPage + this.pageSize
        )
      } else {
        this.total = 0
        this.tablePageData = []
      }
    },
    reset () {
      this.searchStatue = false
      this.searchValue = ''
      this.startPage = 1
      this.getListData()
    },
    delScreening (type, index, id) {
      // 选择是批量删除还是单独删除
      let idList = []
      // let testChildrenList = []
      // console.log(type, index, id)
      if (type === 1) {
        this.$confirm('请确认要全部删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const multipleSelection = this.multipleSelection
          multipleSelection.forEach(item => {
            idList.push(item.id)
          })
          screeningService.delPlan({ idList: idList }).then(res => {
            this.$message({ type: 'success', message: '删除成功!' })
            this.getListData()
          })
        }).catch(() => {
          this.$message({ type: 'info', message: '已取消删除' })
          return false
        })
      } else {
        this.$confirm('此操作将永久删除当前所选子计划及隶属计划, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          idList = [id]
          screeningService.delPlan({ idList: idList }).then(res => {
            this.$message({ type: 'success', message: '删除成功!' })
            this.tablePageData.splice(index, 1)
            this.getListData()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }
    },
    handleCurrentChange (val) {
      this.startPage = val
      if (this.searchStatue) {
        this.dataPages()
      } else {
        this.getListData()
      }
    },
    setPageSize (value) {
      this.pageSize = value
      this.getListData()
    },
    formNull (row, column, cellValue) {
      if (cellValue === '') {
        return '-'
      } else {
        return cellValue
      }
    },
    formatterSchool (row, column, cellValue) {
      if (row.className) {
        return row.schoolName + ' / ' + row.gradeName + ' / ' + row.className
      } else if (row.gradeName) {
        return row.schoolName + ' / ' + row.gradeName
      } else if (row.schoolName) {
        return cellValue
      } else {
        return '-'
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'single-row'
      } else {
        return 'double-row'
      }
    },
    handleSelectionChange (rows) {
      this.multipleSelection = rows
      this.batchNum = rows.length
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  padding: 10px 20px 20px;
  background-color: #fff;
  min-height: 100%;
}
.page .header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 16px;
}
.page .header .el-input,
.page .header .el-button {
  margin-top: 5px;
  margin-bottom: 5px;
}
.header-search .search >>> .el-input__inner {
  border: 1px solid #4daf5a;
  border-radius: 18px;
  margin-right: 16px;
}
.header-button {
  // flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 30px;
}
.table-page {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}
.header-input {
  margin-right: 30px;
}
.header-search {
  display: flex;
  .header-button-action {
    margin-left: 20px;
    display: flex;
  }
}
.header {
  display: flex;
  justify-content: space-between;
}
</style>
